import React from "react";
import Logo from "./Logo";

import Login from "../../image/login2.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className=" shadow-md py-2">
      <div className="container mx-auto flex justify-between max-w-[1400px] px-2">
        <span>
          <Logo />
        </span>
        <span className=" w-[55px]">
          <Link to={"/admin"}>
            <img src={Login} alt="" />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Navbar;
