import React, { useState, useEffect } from "react";
import useLoadData from "../../firebase/LoadDataFirebase";
import Picture from "../Main/Picture";

const SimilarImages = ({ picture }) => {
  const { pictures } = useLoadData();
  const [similarArray, setSimilarArray] = useState([]);

  // Nalezení podobných obrázků prozatím jen v kategoriích
  useEffect(() => {
    const newSimilarArray = pictures.filter(
      (one) =>
        one.category2 === picture.category2 ||
        one.category3 === picture.category3
    );
    setSimilarArray(newSimilarArray);
  }, [pictures]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-4 gap-2 md:gap-4">
      {similarArray.length > 0 &&
        similarArray.slice(0, 15).map((pic) => (
          <div
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            key={pic.id}
            className="max-w-sm mx-auto rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out flex items-center justify-center p-2 "
          >
            <Picture picture={pic} />
          </div>
        ))}
    </div>
  );
};

export default SimilarImages;
