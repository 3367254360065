import React from "react";
import useLoadData from "../../firebase/LoadDataFirebase";
import AdminNav from "../../components/Admin/AdminNav";
import { Link } from "react-router-dom";
import Loading from "../../components/Others/Loading";


const AdminEditAllPicture = () => {
  const { pictures } = useLoadData();
  if(!pictures){
    return <div><Loading/></div>
  }

  
  return (
    <div className="flex flex-col md:flex-row  min-w-full justify-between">
      <AdminNav />
      {/* Obrázky v tabulce */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-100">
          <thead className="bg-purple-600 text-white text-sm">
            <tr>
              <th className="px-2 py-2 ">Obrázek</th>
              <th className="px-2 py-2">Název</th>
              <th className="px-2 py-2">Alt Text</th>
              <th className="px-2 py-2">Popis</th>
              <th className="px-2 py-2">Datum publikace</th>
              {/* <th className="px-2 py-2">Obecné slovo</th> */}
              <th className="px-2 py-2">Seo</th>
              <th className="px-2 py-2">Png</th>
              <th className="px-2 py-2">Check</th>
              <th className="px-2 py-2">Pro děti</th>
              <th className="px-2 py-2">Top Obrázek</th>
              <th className="px-2 py-2">Categori1</th>
              <th className="px-2 py-2">Categori2</th>
              <th className="px-2 py-2">Categori3</th>
              <th className="px-2 py-2">Rozložení</th>
            </tr>
          </thead>
          <tbody>
            {pictures.map((one) => (
              <tr key={one.id} className="border-b text-sm">
                <Link to={`/editallpicture/${one.id}`}> <td className="px-2 py-2">
                  <img src={one.thumbnailUrl} alt="" className="h-12 w-12 object-cover " />
                </td></Link>
               
                <td className="px-2 py-4 ">{one.namePicture.slice(0,25)  ? one.namePicture : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.altTextPicture  ? one.altTextPicture.slice(0,10) : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.description  ? one.description.slice(0,10) : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.datePublished  ? one.datePublished : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.seo  ? one.seo.slice(0,10) : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.png ? "Ano" : "NE"}</td>
                <td className="px-2 py-4">{one.checked ? "Ano" : "NE"}</td>
                <td className="px-2 py-4">{one.forChildren ? "Ano" : "NE"}</td>
                <td className="px-2 py-4">{one.topImage ? "Ano" : "NE"}</td>
                <td className="px-2 py-4">{one.category1 ? one.category1 : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.category2  ? one.category2 : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.category3  ? one.category3 : <span className="text-red-500">Empty</span>}</td>
                <td className="px-2 py-4">{one.layout  ? one.layout : <span className="text-red-500">Empty</span>}</td>
                {/* <td className="px-2 py-4">{one.generalTitle }</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminEditAllPicture;
