import React, { useState } from "react";
import useLoadData from "../../firebase/LoadDataFirebase";
import { doc, deleteDoc } from "firebase/firestore";
import { AiGalleryFirestore } from "../../firebase/config";
import Modal from "react-modal";
import AdminNav from "../../components/Admin/AdminNav";

const AdminNewsletter = () => {
  const { newsletter, fetchNewsletter } = useLoadData();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [saveId, setSaveId] = useState();

  //   funkce mazání z databáze
  const deleteNewsletter = async () => {
    try {
      const newsletterRef = doc(AiGalleryFirestore, `newsletter`, saveId);
      await deleteDoc(newsletterRef);

      // Znvunačtení dat pro aktualizaci stránky
      await fetchNewsletter();
    } catch (error) {
      console.error("Chyba při mazání newsletteru", error);
    }
  };

  // Ovládání modalu
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div class="flex flex-col md:flex-row justify-center  mt-5 overflow-x-auto ">
            <AdminNav />
      <div class="w-full lg:w-3/4 ">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="relative mt-[10%] mx-auto p-5 border w-2/3 md:w-1/3 shadow-lg rounded-md bg-gray-700 border-purple-700"
          contentLabel="Example Modal"
        >
          <h2 class="text-xl font-semibold text-white mb-4">
            Opravdu si přejete vymazat položku?
          </h2>
          <div class="flex justify-around">
            <button
              class="px-4 py-2 bg-yellow-500 text-white font-bold rounded hover:bg-yellow-600 focus:outline-none focus:shadow-outline"
              onClick={closeModal}
            >
              NE
            </button>
            <button
              class="px-4 py-2 bg-red-500 text-white font-bold rounded hover:bg-red-600 focus:outline-none focus:shadow-outline"
              onClick={() => {
                deleteNewsletter();
                closeModal();
              }}
            >
              Ano
            </button>
          </div>
        </Modal>

        <table class="min-w-full leading-normal ">
          <thead>
            <tr>
              <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                ID
              </th>
              <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Name
              </th>
              <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Email
              </th>
              <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {newsletter.map((one) => {
              return (
                <tr key={one.id}>
                  <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {one.id.slice(0, 10)}
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {one.name}
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {one.email}
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      class="text-red-500 hover:text-red-700"
                      onClick={() => {
                        openModal();
                        setSaveId(one.id);
                      }}
                    >
                      Delete
                    </button>

                    {/* <button class="text-red-500 hover:text-red-700" onClick={() => deleteNewsletter(one.id)}>
                    Delete
                  </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminNewsletter;
