import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AdminContextProvider } from "./context/AdminContext"; 
import { ImageContextProvider } from "./context/ImageContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
   <ImageContextProvider>
    <AdminContextProvider>
     
      <App />
     
    </AdminContextProvider>
    </ImageContextProvider>
  </BrowserRouter>
);
