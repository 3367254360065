import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Loading from "../Others/Loading";

const Picture = ({ picture }) => {
  if (!picture) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Helmet></Helmet>

      <Link
        to={`/picture/${picture.id}`}
        title={`${picture.namePicture} - ${picture.seo
          .split(", ")
          .slice(0, 4)
          .join(", ")}`}
        className=" hover:shadow-4xl hover:scale-105 transition-all duration-200 ease-in-out rounded-lg overflow-hidden flex items-center justify-center max-h-[240px] "
      >
        <img
          src={picture.thumbnailUrl}
          alt={picture.altTextPicture}
          loading="lazy"
          className="w-full h-auto"
        />
      </Link>
    </>
  );
};

export default Picture;
