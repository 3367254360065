import React,{useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import OnePicture from "./components/Main/OnePicture";
import AdminPicture from "./Pages/AdminPage/AdminPicture";
import Admin from "./Pages/AdminPage/Admin";
import Footer from "./components/Footer/Footer";
import { useAdminContext } from "./context/AdminContext";
import AdminEditAllPicture from "./Pages/AdminPage/AdminEditAllPicture";
import AdminEditOne from "./Pages/AdminPage/AdminEditOne";
import Header from "./components/Header/Header";
import Category from "./components/Main/Category";
import AdminNewsletter from "./Pages/AdminPage/AdminNewsletter";

const App = () => {
  const { isAdminLoggedIn, handleAdminLogin } = useAdminContext();

  // Funkce pro načtení AdSense skriptu
  const loadAdSenseScript = () => {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5915807524498028";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  };
  
  // Načtení skriptu při prvním renderování komponenty
  useEffect(() => {
    loadAdSenseScript();
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between relative">
      <div>
        <Header />
        <Category />
      </div>

      <div className="flex  justify-start items-start">
        <div className=" "></div>
        <div className="flex items-center justify-center w-full  m-auto max-w-[1400px]">
          <Routes>
            <Route path="/" element={<Home />} />
            {isAdminLoggedIn ? (
              <>
                <Route path="/adminPicture" element={<AdminPicture />} />
                <Route path="/adminNewsletter" element={<AdminNewsletter />} />
                <Route
                  path="/editallpicture"
                  element={<AdminEditAllPicture />}
                />
                <Route path="/editallpicture/:id" element={<AdminEditOne />} />
                <Route path="/admin" element={<Admin />} />
              </>
            ) : (
              <Route
                path="/admin"
                element={<Admin onAdminLogin={handleAdminLogin} />}
              />
            )}
            <Route path="/picture/:id" element={<OnePicture />} />
          </Routes>
        </div>
        <div className=""></div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
