import React from "react";
import { Link } from "react-router-dom";

const AdminNav = () => {
  return (
    <nav className="md:w-[180px] w-full min-h-full bg-slate-300 flex flex-col">
      <Link
        to="/adminPicture"
        className="text-gray-700 hover:text-orange-600 hover:bg-gray-200 px-4 py-2 text-sm font-medium transition duration-300"
      >
        Vytvoření nového obrázku
      </Link>
      <Link
        to="/editallpicture"
        className="text-gray-700 hover:text-orange-600 hover:bg-gray-200 px-4 py-2 text-sm font-medium transition duration-300"
      >
        Editace všech obrázků
      </Link>
      <Link
        to="/adminNewsletter"
        className="text-gray-700 hover:text-orange-600 hover:bg-gray-200 px-4 py-2 text-sm font-medium transition duration-300"
      >
        Uživatelé přihlášení k odběru
      </Link>
    </nav>
  );
};

export default AdminNav;
