export const MainCategory = [
  // Tapety

  {
    id: 1,
    title: "Tapety",
    titleEn: "Wallpapers",
    item: "wallpapers",
    img: "",
    category: [
      {
        id: 11,
        title: "Hry",
        titleEn: "Games",
        item: "games",
        subcategories: [
          {
            id: 111,
            title: "Fantazy",
            titleEn: "Fantasy",
            item: "fantasy",
          },
        ],
      },
      {
        id: 12,
        title: "Příroda",
        titleEn: "Nature",
        item: "nature",
        subcategories: [
          {
            id: 121,
            title: "Sníh",
            titleEn: "Snow",
            item: "snow",
          },
          {
            id: 122,
            title: "Les",
            titleEn: "Forest",
            item: "forest",
          },
          {
            id: 123,
            title: "Poušť",
            titleEn: "Desert",
            item: "desert",
          },
        ],
      },
    ],
  },

  //   Obrázky
  {
    id: 2,
    title: "Obrázky",
    item: "pictures",
    titleEn: "Pictures",
    img: "",
    category: [
      {
        id: 21,
        title: "Zvířata",
        titleEn: "Animals",
        item: "animals",
        subcategories: [
          {
            id: 211,
            title: "Savci domácí",
            titleEn: "Domestic Mammals",
            item: "domesticMammals",
          },
          {
            id: 212,
            title: "Savci divocí",
            titleEn: "Wild Mammals",
            item: "wildMammals",
          },
          {
            id: 213,
            title: "Plazy",
            titleEn: "Reptiles",
            item: "reptiles",
          },
          {
            id: 214,
            title: "Ptácí",
            titleEn: "Birds",
            item: "birds",
          },
          {
            id: 215,
            title: "Mořští",
            titleEn: "Sea Animals",
            item: "seaAnimals",
          },
          {
            id: 216,
            title: "Hmyz",
            titleEn: "Insect",
            item: "insect",
          },
        ],
      },
      {
        id: 22,
        title: "Rostliny",
        titleEn: "Plants",
        item: "plants",
        subcategories: [
          {
            id: 221,
            title: "Kvetoucí",
            titleEn: "Flowering",
            item: "flowering",
          },
          {
            id: 222,
            title: "Stromy",
            titleEn: "Trees",
            item: "trees",
          },
        ],
      },
    ],
  },

  //   Icony
  {
    id: 3,
    title: "Ikony",
    titleEn: "Icons",
    item: "icons",

    img: "",
    category: [
      {
        id: 31,
        title: "Reálné",
        titleEn: "Real",
        item: "real",
        subcategories: [
          {
            id: 311,
            title: "Jídlo",
            titleEn: "Food",
            item: "food",
          },
          {
            id: 312,
            title: "Zvířata",
            titleEn: "Animals",
            item: "animals",
          },
          {
            id: 313,
            title: "Předměty",
            titleEn: "Subjects",
            item: "subjects",
          },
          {
            id: 314,
            title: "Dopravní prostředky",
            titleEn: "Transport",
            item: "transport",
          },
        ],
      },
      {
        id: 32,
        title: "Icony",
        titleEn: "Icons",
        item: "icons",
        subcategories: [
          {
            id: 321,
            title: "Programování",
            titleEn: "Programing",
            item: "programming",
          },
          {
            id: 322,
            title: "Jídlo",
            titleEn: "Food",
            item: "food",
          },
          {
            id: 323,
            title: "Předměty",
            titleEn: "Subjects",
            item: "subjects",
          },
          {
            id: 324,
            title: "Zvířata",
            titleEn: "Animals",
            item: "animals",
          },
        ],
      },
    ],
  },
  //   Pro děti
  {
    id: 4,
    title: "Pro děti",
    titleEn: "Childrens",
    item: "childrens",
    img: "",
    category: [
      {
        id: 41,
        title: "Omalovánky",
        titleEn: "Coloring",
        item: "coloring",
        subcategories: [
          {
            id: 411,
            title: "Zvířata",
            titleEn: "Animals",
            item: "animals",
          },
        ],
      },
    ],
  },

  //   Tetování
  {
    id: 5,
    title: "Tetování ",
    titleEn: "Tattoo",
    item: "tattoo",
    img: "",
    category: [
      {
        id: 51,
        title: "Tetování",
        titleEn: "Tattoo",
        item: "tattoo",
        subcategories: [
          {
            id: 511,
            title: "Zvířata",
            titleEn: "Animals",
            item: "animals",
          },
        ],
      },
    ],
  },
  // Malby
];

const vzor = [
  {
    id: 1,
    title: "Tapety",
    item: "wallpapers",
    img: "",
    category: [
      {
        id: 11,
        title: "Hry",
        item: "games",
        subcategories: [
          {
            id: 111,
            title: "Fantazy",
            item: "fantasy",
          },
        ],
      },
    ],
  },
];
