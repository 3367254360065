import React, { useState } from "react";
import { AiGalleryFirestore } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import Loading from "../Others/Loading";

const Newsletter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [submitForm, setSubmitForm] = useState(true);


  // Odeslání formuláře a nahrání do databáze
  const submitNewsletter = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      setNotification("Vyplňte prosím jméno a email.");
      return;
    }

    try {
      setLoading(true);
      const newCustomer = { name, email };
      await addDoc(collection(AiGalleryFirestore, "newsletter"), newCustomer);
      setNotification("Your email has been successfuly added to the database");
      setName("");
      setEmail("");
      setSubmitForm(false);
    } catch (error) {
      console.error("Chyba při nahrávání ", error);
      setNotification("Došlo k chybě při odesílání.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center flex-col  p-4">
      {loading && <Loading />}
      {notification && <div className="text-purple-500">{notification}</div>}
      {submitForm ? (
        <form
          onSubmit={submitNewsletter}
          className="flex flex-col bg-gray-200 p-4 rounded-lg shadow-md"
        >
          <label
            htmlFor="name"
            className="text-lg font-semibold text-gray-700 mb-2"
          >
            Newsletter
          </label>
          <input
            className="p-2 mb-3 border border-gray-300 text-black rounded outline-none"
            type="text"
            placeholder="Jméno"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            id="name"
          />
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="p-2 mb-4 border text-black border-gray-300 rounded"
            type="email"
            placeholder="Email"
            value={email}
          />
          <button
            type="submit"
            className="bg-purple-500 text-white font-bold py-2 px-4 rounded hover:bg-purple-600 transition duration-300"
          >
            Submit
          </button>
        </form>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Newsletter;
