import React from "react";
import Newsletter from "./Newsletter";

const Footer = () => {
  return (
    <div className="bg-gray-700">
      <div className="container mx-auto text-center p-4 text-white">
        <h2>© 2023, Ai Art Visul. All rights reserved.</h2>

        <Newsletter />
      </div>
    </div>
  );
};

export default Footer;
