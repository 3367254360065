import React from "react";
import { Link } from "react-router-dom";
import LogoPng from "../../image/logo.png";
import { useImageContext } from "../../context/ImageContext";

const Logo = () => {
  const { resetFilter } = useImageContext();

  return (
    <div className="">
      <h1 className="text-white hover:text-gray-200 text-center text-2xl font-bold ">
        <Link
          onClick={() => resetFilter()}
          to={"/"}
          className="flex justify-center items-center"
        >
          <img src={LogoPng} className="w-[50px] " alt="" />

          <span className="pl-4"> AI Art Visual</span>
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
