import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { AiGalleryFirestore } from "./config";


export const useLoadData = () => {
  const [pictures, setPictures] = useState([]);
  const [newsletter, setNewsletter] = useState([]);

  // Načtení obrázků
  useEffect(() => {
    const fetchPictures = async () => {
      try {
        const picturesCollection = collection(AiGalleryFirestore, "pictures");
        const snapshot = await getDocs(picturesCollection);
        const picturesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPictures(picturesData);

        // console.log("Data obrázků úspěšně stažena", picturesData);
      } catch (error) {
        console.error("Chyba při načítání obrázků", error);
      }
    };

    fetchPictures();
  }, []);

  // Načtení Newsleteru

  const fetchNewsletter = async () => {
    try {
      const picturesCollection = collection(AiGalleryFirestore, "newsletter");
      const snapshot = await getDocs(picturesCollection);
      const picturesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewsletter(picturesData);
    } catch (error) {
      console.error("Chyba při načítání obrázků", error);
    }
  };

  // Zavolání znovunačtení newsleteru po zaktualizovaání

  useEffect(() => {
    fetchNewsletter();
  }, []);

  return { pictures, newsletter, fetchNewsletter };
};

export default useLoadData;
