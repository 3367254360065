import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLoadData from "../../firebase/LoadDataFirebase";
import AdminNav from "../../components/Admin/AdminNav";
import { doc, updateDoc } from "firebase/firestore";
import { AiGalleryFirestore } from "../../firebase/config";
import { MainCategory } from "../../data/Categories";
import Loading from "../../components/Others/Loading";

const AdminEditOne = () => {
  const { pictures } = useLoadData();
  const { id } = useParams();
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleMainCategoryChange = (event) => {
    setSelectedMainCategory(event.target.value);
    setFormData({ ...formData, category1: event.target.value });
    setSelectedCategory("");
    setSelectedSubcategory("");
    setFormData({ ...formData, category2: "", category3: "" });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setFormData({ ...formData, category2: event.target.value });
    setSelectedSubcategory("");
    setFormData({ ...formData, category3: "" });
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
    setFormData({ ...formData, category3: event.target.value });
  };

  // Filtrování kategorií a podkategorií na základě výběru
  const filteredCategories = selectedMainCategory
    ? MainCategory.find((cat) => cat.item === selectedMainCategory)?.category
    : [];
  const filteredSubcategories = selectedCategory
    ? filteredCategories.find((cat) => cat.item === selectedCategory)
        ?.subcategories
    : [];

  //   Nalezení správného objektu
  const picture = pictures.find((one) => one.id === id);

  //   vytvoření formuláře
  const [formData, setFormData] = useState({
    namePicture: "",
    altTextPicture: "",
    description: "",
    checked: "",
    seo: "",
    category1: "",
    category2: "",
    category3: "",
    generalTitle: "",
    datePublished: "",
    png: "",
    layout: "",
    forChildren: "",
    topImage: "",
  });

  // Aktualizace formData pokud se změní picture
  useEffect(() => {
    if (picture) {
      setFormData({
        namePicture: picture.namePicture || "",
        altTextPicture: picture.altTextPicture || "",
        description: picture.description || "",
        checked: picture.checked || false,
        seo: picture.seo || "",
        category1: picture.category1 || "",
        category2: picture.category2 || "",
        category3: picture.category3 || "",
        generalTitle: picture.generalTitle || "",
        layout: picture.layout || "",
        forChildren: picture.forChildren || false,
        png: picture.png || false,
        topImage: picture.topImage || false,
        datePublished: picture.datePublished || "",
      });
    }
  }, [picture]);

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification("");
      }, 3000);
    }
  }, [notification]);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else if (e.target.type === "file") {
      const file = e.target.files[0];
      setFormData({ ...formData, image: file });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      category1: selectedMainCategory || formData.category1,
      category2: selectedCategory || formData.category2,
      category3: selectedSubcategory || formData.category3,
    };

    if (
      !formData.namePicture ||
      !formData.altTextPicture ||
      !formData.description
    ) {
      alert("Vyplňte všechna pole formuláře");
      return;
    }
    await updateData(updatedFormData);
  };

  const updateData = async (dataToUpdate) => {
    setLoading(true); // Začátek nahrávání

    if (!id) {
      console.error("Není k dispozici ID obrázku pro aktualizaci");
      setLoading(false); // Konec nahrávání
      return;
    }

    try {
      const docRef = doc(AiGalleryFirestore, "pictures", id);

      await updateDoc(docRef, dataToUpdate);

      setNotification("Dokument úspěšně aktualizován");
    } catch (error) {
      console.error("Chyba při aktualizaci dokumentu: ", error);
      setNotification("Aktualizace selhala");
    }

    setLoading(false); // Konec nahrávání
  };

  if (!pictures) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex">
      <div>
        <AdminNav />
      </div>

      <div className="w-full  ">
        <div className="text-green-500">{notification}</div>
        {loading && <div>Nahrávám...</div>}
        <div className="flex items-center justify-center ">
          {picture && (
            <form
              className="flex flex-col lg:flex-row w-[350px] lg:w-[700px] justify-between m-auto p-4 border rounded"
              onSubmit={handleSubmit}
            >
              {/* Slupec 1 */}
              <div className="flex flex-col min-w-[300px] ">
                <input
                  className="input-style"
                  type="text"
                  name="namePicture"
                  placeholder="Název obrázku"
                  onChange={handleChange}
                  value={formData.namePicture}
                />
                <textarea
                  className="textarea-style"
                  type="text"
                  name="altTextPicture"
                  placeholder="Alt text"
                  onChange={handleChange}
                  value={formData.altTextPicture}
                />
                <textarea
                  className="textarea-style"
                  type="text"
                  name="description"
                  placeholder="Popis"
                  onChange={handleChange}
                  value={formData.description}
                />
                <textarea
                  className="textarea-style"
                  type="text"
                  name="seo"
                  placeholder="Seo"
                  onChange={handleChange}
                  value={formData.seo}
                />

                {/* MainCateogry */}

                <select
                  className="select-style"
                  value={selectedMainCategory}
                  onChange={handleMainCategoryChange}
                >
                  <option className="option-style" value="">
                    {formData.category1}
                  </option>
                  {MainCategory.map((cat) => (
                    <option
                      className="option-style"
                      key={cat.id}
                      value={cat.item}
                    >
                      {cat.title}
                    </option>
                  ))}
                </select>

                {/* Cateogry */}
                <select
                  className="select-style"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  disabled={!selectedMainCategory}
                >
                  <option className="option-style" value="">
                    {formData.category2}
                  </option>
                  {filteredCategories.map((cat) => (
                    <option
                      className="option-style"
                      key={cat.id}
                      value={cat.item}
                    >
                      {cat.title}
                    </option>
                  ))}
                </select>

                {/* Subcatogori */}
                <select
                  className="select-style"
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  disabled={!selectedCategory}
                >
                  <option className="option-style" value="">
                    {formData.category3}
                  </option>
                  {filteredSubcategories.map((subCat) => (
                    <option key={subCat.id} value={subCat.item}>
                      {subCat.title}
                    </option>
                  ))}
                </select>
                <select
                  className="select-style"
                  name="layout"
                  id=""
                  onChange={handleChange}
                >
                  <option className="option-style" value="">
                    {formData.layout}
                  </option>
                  <option className="option-style" value="horizontal">
                    Horizontální
                  </option>
                  <option className="option-style" value="vertical">
                    Vertikální
                  </option>
                  <option className="option-style" value="square">
                    Čtverec
                  </option>
                </select>
              </div>

              {/* Slupec 2 */}

              <div className="flex flex-col ">
                <input
                  className="input-style"
                  type="text"
                  name="generalTitle"
                  placeholder="Obecný název"
                  onChange={handleChange}
                  value={formData.generalTitle}
                />
                <input
                  className="input-style"
                  type="text"
                  name="datePublished"
                  placeholder="Datum publikace"
                  onChange={handleChange}
                  value={formData.datePublished}
                />

                <div className="flex  items-center m-2 justify-between">
                  <label className="label-style" htmlFor="forChildren">
                    Pro děti:
                  </label>
                  <input
                    className="checkbox-style"
                    type="checkbox"
                    name="forChildren"
                    id="forChildren"
                    onChange={handleChange}
                    checked={formData.forChildren}
                  />
                </div>
                <div className="flex  items-center m-2 justify-between">
                  <label className="label-style" htmlFor="topImage">
                    Top Obrázek
                  </label>
                  <input
                    className="checkbox-style"
                    type="checkbox"
                    name="topImage"
                    id="topImage"
                    onChange={handleChange}
                    checked={formData.topImage}
                  />
                </div>
                <div className="flex  items-center m-2 justify-between">
                  <label className="label-style" htmlFor="png">
                    PNG
                  </label>
                  <input
                    className="checkbox-style"
                    type="checkbox"
                    name="png"
                    id="png"
                    onChange={handleChange}
                    checked={formData.png}
                  />
                </div>
                <img
                  className="w-[250px]  m-auto border rounded"
                  src={picture.thumbnailUrl}
                  alt="Náhled"
                />
                <input
                  className="input-style"
                  type="file"
                  name="image"
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="bg-blue-500 text-white p-2 m-2 rounded"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditOne;
