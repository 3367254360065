import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useImageContext } from "../../context/ImageContext";

const SearchBar = () => {
  const { searchBarValue, setSearchBarValue, submitSearchForm } =
    useImageContext();

  return (
    <div className="flex justify-center items-center  ">
      <form
        onSubmit={submitSearchForm}
        className="flex items-center bg-gray-800 min-w-[300px] rounded-full p-1"
        action=""
      >
        <input
          type="text"
          placeholder="Search..."
          className="bg-transparent text-white outline-none placeholder-gray-300 pl-5 w-full"
          onChange={(e) => setSearchBarValue(e.target.value)}
          value={searchBarValue}
        />
        <button
          type="submit"
          className="p-2 rounded-full bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring focus:border-blue-300"
        >
          <IoSearchOutline className="text-white w-8 h-6" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
