import React, { useState, createContext, useContext, useEffect } from "react";
import useLoadData from "../firebase/LoadDataFirebase";
import { useNavigate } from "react-router-dom";

const ImageContext = createContext();

export const ImageContextProvider = ({ children }) => {
  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredPictures, setFilteredPictures] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const { pictures } = useLoadData();
  const navigate = useNavigate();

  // načítání pokud data ještě nejsou načtena z databíze
  useEffect(() => {
    if (pictures.length > 0) {
      setFilteredPictures(pictures);
      setDataLoaded(true);
    }
  }, [pictures]);

  // Formulář pro vyhledávání
  const submitSearchForm = (e) => {
    e.preventDefault();
    navigate("/");
    setSearchBarValue(e.target.value);

    const searchingText = searchBarValue.toLocaleLowerCase();

    const filtered = pictures.filter(
      (one) =>
        one.category1.toLocaleLowerCase().includes(searchingText) ||
        one.category2.toLocaleLowerCase().includes(searchingText) ||
        one.category3.toLocaleLowerCase().includes(searchingText) ||
        one.altTextPicture.toLocaleLowerCase().includes(searchingText) ||
        one.namePicture.toLocaleLowerCase().includes(searchingText) ||
        one.description.toLocaleLowerCase().includes(searchingText) ||
        one.seo.toLocaleLowerCase().includes(searchingText)
    );

    setFilteredPictures(filtered);
    setSearchBarValue("");
  };

  // Filtrování podle kategorii
  useEffect(() => {
    setFilteredPictures("");
    navigate("/");
    const categorySearch = () => {
      const filtered = pictures.filter(
        (one) =>
          (!mainCategory || one.category1 === mainCategory) &&
          (!category || one.category2 === category) &&
          (!subCategory || one.category3 === subCategory)
      );

      setFilteredPictures(filtered);
    };

    categorySearch();
  }, [mainCategory, category, subCategory]);

  //   Vybrání kategorie do filtru
  const resetFilter = () => {
    setSubCategory("");
    setCategory("");
    setMainCategory("");
    setActiveCategory(null);
    navigate("/");
  };

  return (
    <ImageContext.Provider
      value={{
        setSubCategory,
        setCategory,
        setMainCategory,
        mainCategory,
        category,
        subCategory,
        filteredPictures,
        searchBarValue,
        setSearchBarValue,
        submitSearchForm,
        dataLoaded,
        setActiveCategory,
        activeCategory,
        resetFilter
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export const useImageContext = () => {
  return useContext(ImageContext);
};
