import React, { useState } from "react";
import Picture from "../components/Main/Picture";
import ReactPaginate from "react-paginate";
import "../css/strankovani.css";
import { useImageContext } from "../context/ImageContext";
import Loading from "../components/Others/Loading";
import AdSenseAd from "../components/ads/AdSenseAd";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;

  const { filteredPictures, dataLoaded } = useImageContext();

  // Výpočet aktuálně zobrazených obrázků
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPictures = filteredPictures.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // postunustí strany a scrolování k vrcholu
  const handlePageClick = (selectedPage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(selectedPage.selected);
  };

  // Vypočítá celkový počet stránek na základě celkového počtu obrázků
  const pageCount = Math.ceil(filteredPictures.length / itemsPerPage);

  return (
    <div className="container mx-auto p-2 md:p-4">
      {dataLoaded ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4">
          {currentPictures.length >= 1 ? (
            currentPictures.map((picture) => (
              <div
                key={picture.id}
                className="max-w-sm mx-auto rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out flex items-center justify-center p-2"
              >
                <Picture picture={picture} />
              </div>
            ))
          ) : (
            <div className="w-full text-center">
              Unfortunately, the selected category is currently empty.
            </div>
          )}
        </div>
      ) : (
        <div className="w-full text-center">
          <Loading />
        </div>
      )}

      <div><AdSenseAd/></div>

      <div className="flex justify-center my-4">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active2"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
        />
      </div>
    </div>
  );
};

export default Home;
