import React, { useEffect, useState } from "react";
import { AiGalleryStorage, AiGalleryFirestore } from "../../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import imageCompression from "browser-image-compression";
import { Link } from "react-router-dom";
import { MainCategory } from "../../data/Categories";

import AdminNav from "../../components/Admin/AdminNav";

const AdminPicture = () => {
  const [notification, setNotification] = useState("");
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleMainCategoryChange = (event) => {
    setSelectedMainCategory(event.target.value);
    // Resetujeme další selecty
    setSelectedCategory("");
    setSelectedSubcategory("");
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    // Resetujeme select podkategorií
    setSelectedSubcategory("");
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };

  // Filtrování kategorií a podkategorií na základě výběru
  const filteredCategories = selectedMainCategory
    ? MainCategory.find((cat) => cat.item === selectedMainCategory)?.category
    : [];
  const filteredSubcategories = selectedCategory
    ? filteredCategories.find((cat) => cat.item === selectedCategory)
        ?.subcategories
    : [];

  const [formData, setFormData] = useState({
    namePicture: "",
    altTextPicture: "",
    description: "",
    checked: false,
    seo: [],
    category1: "",
    png: false,
    category2: "",
    category3: "",
    datePublished: "",
    generalTitle: "",
    layout: "",
    forChildren: false,
    topImage: false,
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification("");
      }, 3000);
    }
  }, [notification]);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else if (e.target.type === "file") {
      const file = e.target.files[0];
      setFormData({ ...formData, image: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const uploadImageAndSaveData = async () => {
    setLoading(true);
    if (formData.image) {
      const fileRef = ref(AiGalleryStorage, `images/${formData.namePicture}`);
      const thumbnailRef = ref(
        AiGalleryStorage,
        `thumbnails/${formData.namePicture}`
      );

      try {
        await uploadBytes(fileRef, formData.image);
        const imageUrl = await getDownloadURL(fileRef);

        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(formData.image, options);
        await uploadBytes(thumbnailRef, compressedFile);
        const thumbnailUrl = await getDownloadURL(thumbnailRef);

        const newPicture = {
          namePicture: formData.namePicture,
          altTextPicture: formData.altTextPicture,
          description: formData.description,
          seo: formData.seo,
          category1: selectedMainCategory,
          category2: selectedCategory,
          category3: selectedSubcategory,
          generalTitle: formData.generalTitle,
          topImage: formData.topImage,
          layout: formData.layout,
          png: formData.png,
          forChildren: formData.forChildren,
          imageUrl: imageUrl,
          thumbnailUrl: thumbnailUrl,
          datePublished: formatDate(new Date()),
          checked: formData.checked,
        };

        await addDoc(collection(AiGalleryFirestore, "pictures"), newPicture);
        setNotification("Data úspěšně nahrána");
        setFormData({
          namePicture: "",
          altTextPicture: "",
          description: "",
          seo: "",
          checked: false,
          category1: "",
          category2: "",
          category3: "",
          layout: "",
          forChildren: false,
          png: false,
          image: null,
          datePublished: "",
          topImage: "",
          generalTitle: "",
        });
        setImagePreview(null);
      } catch (error) {
        console.error("Chyba při nahrávání obrázku a ukládání dat: ", error);
        setNotification("Nahrávání selhalo");
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.namePicture ||
      !formData.altTextPicture ||
      !formData.description ||
      !formData.image
    ) {
      alert("Vyplňte všechna pole formuláře");
      return;
    }
    await uploadImageAndSaveData();
  };

  return (
    <div className=" md:p-8 flex flex-col md:flex-row justify-between w-full">
      <AdminNav />
      <section>
        <div className="flex items-center justify-end mt-6">    <Link className="bg-green-700 text-white py-1 px-2  rounded-lg" to={"/"}>
          Zpět na home
        </Link>

        </div>
    

        <h1 className="text-center text-green-700 py-4 font-bold text-2xl">
          Nahrávací formulář
        </h1>
        <div className="text-green-500">{notification}</div>
        {loading && <div>Nahrávám...</div>}
        <form
          className="flex flex-col lg:flex-row w-[350px] lg:w-[700px] justify-between m-auto p-4 border rounded"
          onSubmit={handleSubmit}
        >
          {/* Slupec 1 */}
          <div className="flex flex-col min-w-[300px] ">
            <input
              className="p-2 m-2 border rounded"
              type="text"
              name="namePicture"
              placeholder="Název obrázku"
              onChange={handleChange}
              value={formData.namePicture}
            />
            <textarea
              className="p-2 m-2 border rounded"
              type="text"
              name="altTextPicture"
              placeholder="Alt text"
              onChange={handleChange}
              value={formData.altTextPicture}
            />
            <textarea
              className="p-2 m-2 border rounded"
              type="text"
              name="description"
              placeholder="Popis"
              onChange={handleChange}
              value={formData.description}
            />
            <textarea
              className="p-2 m-2 border rounded"
              type="text"
              name="seo"
              placeholder="Seo"
              onChange={handleChange}
              value={formData.seo}
            />

            {/* MainCateogry */}

            <select
              className="p-2 m-2 border rounded"
              value={selectedMainCategory}
              onChange={handleMainCategoryChange}
            >
              <option value="">Vyberte hlavní kategorii</option>
              {MainCategory.map((cat) => (
                <option key={cat.id} value={cat.item}>
                  {cat.title}
                </option>
              ))}
            </select>

            {/* Cateogry */}
            <select
              className="p-2 m-2 border rounded"
              value={selectedCategory}
              onChange={handleCategoryChange}
              disabled={!selectedMainCategory}
            >
              <option value="">Vyberte kategorii</option>
              {filteredCategories.map((cat) => (
                <option key={cat.id} value={cat.item}>
                  {cat.title}
                </option>
              ))}
            </select>

            {/* Subcatogori */}
            <select
              className="p-2 m-2 border rounded"
              value={selectedSubcategory}
              onChange={handleSubcategoryChange}
              disabled={!selectedCategory}
            >
              <option value="">Vyberte podkategorii</option>
              {filteredSubcategories.map((subCat) => (
                <option key={subCat.id} value={subCat.item}>
                  {subCat.title}
                </option>
              ))}
            </select>

            {/* Kategorie */}

            <select
              className="p-2 m-2 border rounded"
              name="layout"
              id=""
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="horizontal">Horizontální</option>
              <option value="vertical">Vertikální</option>
              <option value="square">Čtverec</option>
            </select>
          </div>

          {/* Slupec 2 */}

          <div className="flex flex-col ">
            {imagePreview && (
              <img
                className="w-[250px]  m-auto border rounded"
                src={imagePreview}
                alt="Náhled"
              />
            )}

            <input
              className="p-2 m-2 border rounded"
              type="text"
              name="generalTitle"
              placeholder="Obecný název"
              onChange={handleChange}
              value={formData.generalTitle}
            />

            <div className="flex  items-center m-2">
              <label className="mr-2" htmlFor="forChildren">
                Pro děti:
              </label>
              <input
                type="checkbox"
                name="forChildren"
                id="forChildren"
                onChange={handleChange}
                checked={formData.forChildren}
              />
            </div>
            <div className="flex  items-center m-2">
              <label className="mr-2" htmlFor="png">
                PNG
              </label>
              <input
                type="checkbox"
                name="png"
                id="png"
                onChange={handleChange}
                checked={formData.png}
              />
            </div>
            <div className="flex  items-center m-2">
              <label className="mr-2" htmlFor="topImage">
                Top Obrázek
              </label>
              <input
                type="checkbox"
                name="topImage"
                id="topImage"
                onChange={handleChange}
                checked={formData.topImage}
              />
            </div>

            <input
              className="p-2 m-2 border rounded"
              type="file"
              name="image"
              onChange={(e) => handleChange(e)}
            />
            <button
              className="bg-blue-500 text-white p-2 m-2 rounded"
              type="submit"
            >
              Nahrát do databáze
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AdminPicture;
