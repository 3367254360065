import React from 'react';

const AdSenseAd = ({ adClient, adSlot }) => {
  const adStyle = {
    display: 'block',
    width: 'auto',
    height: 'auto',
  };

  console.log(adClient, adSlot);
  return (
    <ins className="adsbygoogle"
         style={adStyle}
         data-ad-client={adClient}
         data-ad-slot={adSlot}
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  );
};

export default AdSenseAd;
