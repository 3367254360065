import React, { useState } from "react";
import { MainCategory } from "../../data/Categories";
import { RiFilterOffFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useImageContext } from "../../context/ImageContext";

const Category = () => {
  const { setSubCategory, setCategory, setMainCategory, setActiveCategory, activeCategory, resetFilter } = useImageContext();

  const navigate = useNavigate();

 
  const [activeSubcategories, setActiveSubcategories] = useState([]);

  // Ovládání kategorii
  const handleClick = (mainCategory) => {
    if (mainCategory.id === activeCategory) {
      setActiveSubcategories([]);
    } else {
      setActiveCategory(mainCategory.id);
      setActiveSubcategories(mainCategory.category);
    }
  };




  return (
    <nav className="flex flex-col bg-gray-800 w-full items-top justify-center p-2 sdfjlfjd ">
      <div className="flex flex-wrap items-center justify-center sm:gap-6 gap-1 py-2">
        {MainCategory.map((mainCategory) => (
          <div
            className="md:mx-2 flex flex-row  items-center justify-center "
            key={mainCategory.id}
          >
            <button
              className="text-white font-semibold hover:text-yellow-500 transition-colors shadow-sm shadow-gray-600 px-2 py-1 rounded-lg"
              onClick={() => {
                handleClick(mainCategory);
                setMainCategory(mainCategory.item);
                setSubCategory("");
                setCategory("");
              }}
            >
              {mainCategory.titleEn}
            </button>
          </div>
        ))}
        <button
          className="text-white font-semibold hover:text-yellow-500 transition-colors shadow-sm shadow-gray-600 px-2 py-1 rounded-lg"
          onClick={() => resetFilter()}
        >
          <RiFilterOffFill />
        </button>
      </div>
      <div className="w-full flex  justify-center gap-10 bg-gray-800 text-white shadow-lg ">
        {activeCategory ? (
          activeSubcategories.map((category) => (
            <div key={category.id}>
              <button
                onClick={() => {
                  setCategory(category.item);
                  setSubCategory("");
                }}
                className="text-white font-semibold border-b cursor-pointer"
              >
                {category.titleEn}
              </button>
              {category.subcategories.map((sub) => (
                <p
                  key={sub.id}
                  onClick={() => {
                    setSubCategory(sub.item);
                    setCategory(category.item);
                  }}
                  className="text-gray-50 hover:text-yellow-500 cursor-pointer"
                >
                  {sub.titleEn}
                </p>
              ))}
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </nav>
  );
};

export default Category;
