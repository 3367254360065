import React from "react";
import { useParams } from "react-router-dom";
import useLoadData from "../../firebase/LoadDataFirebase";
import BackHome from "../Others/BackHome";
import SimilarImages from "./SimilarImages";
import Modal from "react-modal";
import Loading from "../Others/Loading";

const OnePicture = () => {
  const { pictures } = useLoadData();
  const { id } = useParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // Nalezené příslušného obrázku na stránce
  const picture = pictures.find((one) => one.id === id);

  // Ovládání modalu
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!picture) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full lg:px-20 flex flex-col items-center justify-center py-8 bg-gray-100 rounded-2xl">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="relative mt-[5%] mx-auto p-2 border w-11/12 h-5/6 md:w-4/5 md:h-4/5 shadow-lg rounded-md bg-white"
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-white hover:text-black transition-all bg-black w-[30px] h-[30px] hover:bg-gray-200 rounded-full text-sm p-1.5"
        >
          X
        </button>
        <img
          title={`${picture.namePicture} - ${picture.seo
            .split(", ")
            .slice(0, 4)
            .join(", ")}`}
          src={`${picture.imageUrl}`}
          alt={picture.altTextPicture}
          className="w-full h-full object-contain"
        />
      </Modal>

      <div className="flex items-end justify-end w-full px-4">
        <BackHome />
      </div>

      <section className="flex flex-col sm:flex-row justify-between w-full px-6 gap-4">
        {/* Obrázek */}
        <div className=" sm:w-2/3 lg:w-1/2 mt-5 ">
          {picture && (
            <div
              onClick={() => openModal()}
              className=" mx-auto cursor-pointer  "
            >
              <img
                title={`${picture.namePicture} - ${picture.seo
                  .split(", ")
                  .slice(0, 4)
                  .join(", ")}`}
                src={`${picture.imageUrl}`}
                alt={picture.altTextPicture}
                className="rounded-lg shadow-xl "
              />
            </div>
          )}
        </div>

        {/* Text */}
        <div className="sm:w-1/3">
          <h2 className="text-2xl font-bold text-gray-800 text-center my-4">
            {picture && picture.namePicture}
          </h2>
          <h3 className="underline">Description:</h3>
          <p>{picture && picture.description}</p>
        </div>
      </section>
      <div className="">
        <h2 className="text-2xl font-bold text-gray-800 text-center my-4">
          Similar images{" "}
        </h2>
        <SimilarImages picture={picture} />
      </div>
    </div>
  );
};

export default OnePicture;
