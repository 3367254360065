import React, { useState, createContext, useContext } from "react";

// Vytvoření kontextu
const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const handleAdminLogout = () => {
    setIsAdminLoggedIn(false);
  };

  return (
    <AdminContext.Provider
      value={{
        isAdminLoggedIn,
        handleAdminLogout,
        setIsAdminLoggedIn,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

// Export kontextu
export const useAdminContext = () => {
  return useContext(AdminContext);
};
