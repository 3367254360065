import React from 'react'
import { Link } from 'react-router-dom'

const BackHome = () => {
  return (
<Link to={"/"} className="text-white bg-purple-600 hover:bg-orange-600 font-bold py-2 px-4 rounded shadow">
  Home
</Link>

  )
}

export default BackHome
