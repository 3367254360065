import React from "react";
import Navbar from "./Navbar";
import SearchBar from "./SearchBar";

const Header = () => {
  return (
    <header className="header-background  ">
      <Navbar />
      <div className="flex items-center justify-center mt-[150px]">
        <SearchBar />
      </div>
    </header>
  );
};

export default Header;
