import React, { useState } from "react";
import { useAdminContext } from "../../context/AdminContext";
import AdminNav from "../../components/Admin/AdminNav";

const Admin = () => {
  const { isAdminLoggedIn, setIsAdminLoggedIn } = useAdminContext();
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Validace admina
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      password === process.env.REACT_APP_ADMIN_PASSWORD &&
      userName.toLocaleLowerCase() === process.env.REACT_APP_ADMIN_NAME
    ) {
      setIsAdminLoggedIn(true);
    } else {
      setErrorMessage("Nesprávné heslo. Zkuste to znovu.");
    }
  };

  return (
    <div className="p-8 w-full">
      {isAdminLoggedIn ? (
        <div>
          {/* Admin Sekce */}
          <section className="flex  flex-row">
            <div className="mr-4 mb-4 md:mb-0">
              <AdminNav />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-700">
                Vítejte na hlavní stránce administrátora
              </h2>
            </div>
          </section>
        </div>
      ) : (
        <div className="max-w-sm mx-auto my-40">
          <h2 className="text-xl font-bold mb-4 text-center text-gray-700">
            Heslo pro přihlášení
          </h2>
          {errorMessage && (
            <div className="text-red-500 text-center">{errorMessage}</div>
          )}
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <input
              className="p-2 m-2 border border-gray-300 rounded w-full"
              type="text"
              placeholder="Name"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
            <input
              className="p-2 m-2 border border-gray-300 rounded w-full"
              type="password"
              placeholder="Heslo"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded shadow"
            >
              Přihlásit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Admin;
